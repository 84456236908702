import React from "react";
import { Link, withRouter } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { Nav, Navbar, NavDropdown } from "react-bootstrap";



function Navigation(props) {
  return (
    <div className="navigation fixed-top">

  <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="mobmenu">
      <Navbar.Brand href="#home">
       <img src="images/eig-logo.png" class="logo" />
       
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        
        <ul class="navbar-nav ml-auto">
              <li
                class={`nav-item  ${
                  props.location.pathname === "/" ? "active" : ""
                }`}
              >

                <HashLink to="/#home">Home</HashLink>
              </li>
              <li
                class={`nav-item  ${
                  props.location.pathname === "#gallery" ? "active" : ""
                }`}
              >
              <HashLink to="/#gallery">Gallery</HashLink>

                
              </li>
              <li
                class={`nav-item  ${
                  props.location.pathname === "#roadmap" ? "active" : ""
                }`}
              >
                <HashLink to="/#roadmap">Roadmap</HashLink>
              </li>

              <li
                class={`nav-item  ${
                  props.location.pathname === "#faq" ? "active" : ""
                }`}
              >
                <HashLink to="/#faq">FAQ</HashLink>
              </li>

              <li
                class={`nav-item  ${
                  props.location.pathname === "#future-plans" ? "active" : ""
                }`}
              >
                <HashLink to="/#future-plans">Future Plans</HashLink>
              </li>


              <li
                class={`nav-item  ${
                  props.location.pathname === "#team" ? "active" : ""
                }`}
              >
                <HashLink to="/#team">Team</HashLink>
              </li>
            </ul>
      </Navbar.Collapse>
    </Navbar>


      <nav class="navbar navbar-expand navbar-dark bg-dark deskmenu">
        <div class="container">
          <Link class="navbar-brand" to="/">
            <img src="images/eig-logo.png" class="logo" />
          </Link>

          <div>
            <ul class="navbar-nav ml-auto">
              <li
                class={`nav-item  ${
                  props.location.pathname === "/" ? "active" : ""
                }`}
              >

                <HashLink to="/#home">Home</HashLink>
              </li>
              <li
                class={`nav-item  ${
                  props.location.pathname === "#gallery" ? "active" : ""
                }`}
              >
              <HashLink to="/#gallery">Gallery</HashLink>

                
              </li>
              <li
                class={`nav-item  ${
                  props.location.pathname === "#roadmap" ? "active" : ""
                }`}
              >
                <HashLink to="/#roadmap">Roadmap</HashLink>
              </li>

              <li
                class={`nav-item  ${
                  props.location.pathname === "#faq" ? "active" : ""
                }`}
              >
                <HashLink to="/#faq">FAQ</HashLink>
              </li>

              <li
                class={`nav-item  ${
                  props.location.pathname === "#future-plans" ? "active" : ""
                }`}
              >
                <HashLink to="/#future-plans">Future Plans</HashLink>
              </li>


              <li
                class={`nav-item  ${
                  props.location.pathname === "#team" ? "active" : ""
                }`}
              >
                <HashLink to="/#team">Team</HashLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default withRouter(Navigation);