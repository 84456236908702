import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Button } from 'reactstrap';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  

} from 'reactstrap';
import { Navigation, Footer, About, Contact, Home } from "./components";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";



function App(props) {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="App">
        <Router>
            <Navigation />

            <Switch>
              <Route path="/" exact component={() => <Home />} />
              <Route path="/about" exact component={() => <About />} />
              <Route path="/contact" exact component={() => <Contact />} />
            </Switch>

            <Footer />
        </Router>


      

      
    </div>
  );
}

export default App;
