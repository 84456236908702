import React, { useState } from 'react';
import { Link, withRouter } from "react-router-dom";
import {Button, Card, CardBody, CardHeader, Collapse} from 'reactstrap';
import {Accordion} from 'react-bootstrap';
import { bounceIn } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import { CSSTransition }  from 'react-transition-group'; 
import { Helmet } from 'react-helmet'




function Home(props) {

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const bounceAnimation = keyframes`${bounceIn}`;

  const BounceIn = styled.div`
      animation: 1s ${bounceAnimation};
  `; 


	return(
      <>
      <Helmet>
          <title>Ernest In Disguise </title>
        </Helmet>
			<div className=" home-banner-bg" id="home">
      <div class="container">
        <div class="row align-items-center text-left my-5">
            
          <div class="col-lg-12 text-center my-5">
              
              <div>
                <img src="images/eig-logo.png" class="my-5 home-top-logo" />
              </div>
              
              <BounceIn><Button color="primary" className="mint-button">Mint</Button></BounceIn>
            
          </div>
        </div>
        </div>
        </div>

    <div className="about">
        <div class="container">
        
        <div class="row align-items-center text-left my-5">
            

          <div class="col-lg-6  my-5 text-white">
              
              <CSSTransition  transitionName="example"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}>
                      <h2 class="text-left">Who is Ernest?</h2>    
              </CSSTransition>
              
             <p>Ernest is a friendly alien from the Cryzal galaxy. A misfit who found love and connection here on planet earth. He creates spooky, cool, playful, and wicked disguises to join us humans as we celebrate together on special occasions. </p>

<p>This is Ernests’ first 6,666 spooky disguises collection, basing all traits from our most traditional Halloween costumes, movies, and characters, randomly generating them on the Solana blockchain.</p>
<p>Surprise surprise…13/6666 are super rare unique 1 of 1 costume designs based on some Earths most iconic Halloween characters, including Chucky, It and more. </p>

              
              <Button color="primary" className="mint-button">Learn More</Button>
            
          </div>
          <div class="col-lg-6  my-5">
                  
                  <img src="images/new-aliens.gif" class="my-5  alien-gif" />
              
            
          </div>
        </div>
      </div>
    </div>




    

    

    <div className="gallery discord" id="roadmap">
        <div class="container">
        
        <div class="row align-items-center text-left my-5">
          <div class="col-lg-12  my-5">
              <h3 className="text-center text-white">DISCORD</h3>
          </div>
        </div>
        
        <div class="row align-items-center text-left my-5 images " >
            
          <div class="col-lg-12  my-5  ">
              
              <a href="https://discord.gg/mk5PgvHwDf" target="_blank">
                <img src="images/roadmap.jpg"   />
              </a>
            
          </div>
          
          
        </div>
      </div>
    </div>

    <div className="faq" id="faq">
        <div class="container">
        
        <div class="row align-items-center text-left my-5">
            
          
          <div class="col-lg-12 text-center  my-5 text-white">
              
              <h2>FAQ</h2>
              
              
              
            
          
          
            
          </div>


          <Accordion defaultActiveKey="0">
            
            <Accordion.Item eventKey="0">
              <Accordion.Header>What's An NFT?</Accordion.Header>
              <Accordion.Body>
                NFT Stands For Non-Fungible Token. A Digital Asset That Is Unique And Therefore Not Interchangeable.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>Where Does My NFT Go After I Purchase My Originator(s)?</Accordion.Header>
              <Accordion.Body>
                OpenSea Marketplace
              </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="2">
              <Accordion.Header>Mint Price?</Accordion.Header>
              <Accordion.Body>
                .08 ETH
              </Accordion.Body>
            </Accordion.Item>

             <Accordion.Item eventKey="3">
              <Accordion.Header>What is the Mint Count?</Accordion.Header>
              <Accordion.Body>
                10,000
              </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="4">
              <Accordion.Header>How many mints per transaction?</Accordion.Header>
              <Accordion.Body>
                15
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>How to register for the whitelist?</Accordion.Header>
              <Accordion.Body>
                Click Here
              </Accordion.Body>
            </Accordion.Item>


          </Accordion>
        </div>
      </div>
    </div>


    <div className="faq future-plans" id="future-plans">
        <div class="container">
        
        <div class="row align-items-center text-left my-5">
            
          
          <div class="col-lg-12 text-center  my-5 text-white">
              
              <h2>Future plans and utility</h2>
              
              
                  

                  <div class="future-box">
                    <img src="images/shuttle.png" class="shutle-image"   />
                      <ul>
                        
                        <li>Every week for the next 3 months after launch date, 100% of royalties (8%) will be used to sweep up the floor.</li>

<li>Spaceship Special- There will be a surprise spaceship airdrop within the first two months to all Ernest holders, in order to help Ernest travel to and from Earth.</li>

<li>Holders of both Ernest and his spaceship will gain access to a private member only discord, this discord will help holders navigate their way through the NFT metaverse and allow members to keep up to date with our most discrete and exciting information within the Ernest project.</li>

<li>More surprises to come!</li>

                      </ul>

                  </div>
            
          
          
            
          </div>


          
        </div>
      </div>
    </div>




    <div className="meet-team" id="team">
        <div class="container">

        <div class="row align-items-center text-left my-5">
          <div class="col-lg-12  my-5">
              <h3 className="text-center text-white">Meet The Team</h3>
          </div>
        </div>
        <div class="row align-items-center text-left my-5 images " >
            
          <div class="col-lg-4  my-5">   
            <div class="single-team">           
                <img src="images/nft/2.jpg"   />
                <div class="row team-detail">
                  <div class="col-lg-9">
                    <div class="">
                      <a href="https://twitter.com/CavemanAron" target="_blank">Anthony</a><br />
                      Founder & Writer 
                    </div>
                  </div>
                  <div class="col-lg-3 team-social">
                    <img src="images/twitter-icon.png"   />
                  </div>

                  <div class="col-lg-12">
                    <div class="team-text">
                        <br />Has been a member of the crypto community since 2017. His first horror movie experience.
                    </div>
                  </div>

                </div>            
              </div>
          </div>

          <div class="col-lg-4  my-5">   
            <div class="single-team">           
                <img src="images/nft/3.jpg"   />
                <div class="row team-detail">
                  <div class="col-lg-9">
                    <div class="">
                      <a href="#" target="_blank">John</a><br />
                        Founder & Writer
                    </div>
                  </div>
                  <div class="col-lg-3 team-social">
                    <img src="images/twitter-icon.png"   />
                  </div>
                  <div class="col-lg-12">
                    <div class="team-text">
                        <br />A monster with diamond hands.<br /><br /><br />
                    </div>
                  </div>
                </div>            
              </div>
          </div>

          <div class="col-lg-4  my-5">   
            <div class="single-team">           
                <img src="images/nft/4.jpg"   />
                <div class="row team-detail">
                  <div class="col-lg-9">
                    <div class="">
                      <a href="https://twitter.com/JLBEEZY1" target="_blank">JLBeezy</a><br />
                      Founder & Market Analyst
                    </div>
                  </div>
                  <div class="col-lg-3 team-social">
                    <img src="images/twitter-icon.png"   />
                  </div>
                  <div class="col-lg-12">
                    <div class="team-text">
                        <br />Active member in the crypto community since 2017. Knows when to walk away before people scream.
                    </div>
                  </div>
                </div>            
              </div>
          </div>

          <div class="col-lg-4  my-5">   
            <div class="single-team">           
                <img src="images/nft/5.jpg"   />
                <div class="row team-detail">
                  <div class="col-lg-9">
                    <div class="">
                      <a href="#" target="_blank">Nathan</a><br />
                      Founder & Director
                    </div>
                  </div>
                  <div class="col-lg-3 team-social">
                    <img src="images/twitter-icon.png"   />
                  </div>
                  <div class="col-lg-12">
                    <div class="team-text">
                        <br />Gets a kick out of seeing fear amongst the crowd.<br /><br /><br />
                    </div>
                  </div>
                </div>            
              </div>
          </div>

          <div class="col-lg-4  my-5">   
            <div class="single-team">           
                <img src="images/nft/6.jpg"   />
                <div class="row team-detail">
                  <div class="col-lg-9">
                    <div class="">
                      <a href="#" target="_blank">AZ</a><br />
                      Founder & Influencer relations
                    </div>
                  </div>
                  <div class="col-lg-3 team-social">
                    <img src="images/twitter-icon.png"   />
                  </div>
                  <div class="col-lg-12">
                    <div class="team-text">
                        <br />Founder of Corrupt Catz and involved in Sol chicks. The Frankenstein of NFTS.
                    </div>
                  </div>
                </div>            
              </div>
          </div>

          <div class="col-lg-4  my-5">   
            <div class="single-team">           
                <img src="images/nft/7.jpg"   />
                <div class="row team-detail">
                  <div class="col-lg-9">
                    <div class="">
                      <a href="https://twitter.com/dattoliart" target="_blank">Carlos Dattoli</a><br />
                      Professional Illustrator/Concept Artist
                    </div>
                  </div>
                  <div class="col-lg-3 team-social">
                    <img src="images/twitter-icon.png"   />
                  </div>
                  <div class="col-lg-12">
                    <div class="team-text">
                        <br />Working in the Pop Culture Industry- Marvel,DC,StarWars,Power Rangers. Creator of the Horror Show!
                    </div>
                  </div>
                </div>            
              </div>
          </div>


          <div class="col-lg-4  my-5">   
            <div class="single-team">           
                <img src="images/nft/7.jpg"   />
                <div class="row team-detail">
                  <div class="col-lg-9">
                    <div class="">
                      <a href="#" target="_blank">Steve</a><br />
                      Web Developer
                    </div>
                  </div>
                  <div class="col-lg-3 team-social">
                    <img src="images/twitter-icon.png"   />
                  </div>
                  <div class="col-lg-12">
                    <div class="team-text">
                        <br />Crypto Watcher- HODL
                    </div>
                  </div>
                </div>            
              </div>
          </div>



          

         
          
        </div>
      </div>
    </div>
    </>
		);
}

export default withRouter(Home);