import React from "react";
import { Link, withRouter } from "react-router-dom";

function Contact(props) {
	return(

			<div className="contact">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <img
              class="img-fluid rounded mb-4 mb-lg-0"
              src="http://placehold.it/900x400"
              alt=""
            />
          </div>
          <div class="col-lg-5">
            <h1 class="font-weight-light">Contact</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>
        </div>
      </div>

      galley

      <div className="gallery gallery-bg" id="gallery">
        <div class="container">
        
        <div class="row align-items-center text-left my-5">
          <div class="col-lg-12  my-5">
              <h3 className="text-center text-white">Gallery</h3>
          </div>
        </div>
        <div class="row align-items-center text-left my-5 images " >
            
          <div class="col-lg-3  my-5  ">
              
              <img src="images/nft/new/1.jpg"   />
            
          </div>

          <div class="col-lg-3  my-5">
              
              <img src="images/nft/new/2.jpg" />
              
            
          </div>

          <div class="col-lg-3  my-5">
              
              <img src="images/nft/3.jpg" />
              
            
          </div>
         
          

          <div class="col-lg-3  my-5">
              
              <img src="images/nft/new/4.jpg" />
              
            
          </div>

           <div class="col-lg-3  my-5">
              
              <img src="images/nft/4.jpg" />
            
          </div>

          <div class="col-lg-3  my-5">
              
              <img src="images/nft/7.jpg" />
              
            
          </div>

          <div class="col-lg-3  my-5">
              
              <img src="images/nft/new/5.jpg" />
              
            
          </div>
          
        </div>
      </div>
    </div>
    </div>

		);
}

export default withRouter(Contact);